<template>
  <div class="invite">
    <div class="img-header">
      <img src="../../assets/images/join-header.png" alt="" />
    </div>
    <page-header
      :tabs="tabs"
      name="加入我们"
      :active-tab="activeTab"
      @tab-change="tabChange"
    ></page-header>
    <transition>
      <router-view />
    </transition>
  </div>
</template>

<script>
import PageHeader from "../../components/PageHeader/PageHeader.vue";

export default {
  data() {
    return {
      // tabs: ["企业文化", "员工风采", "我要应聘", "招采平台"],
      tabs: ["员工风采", "我要应聘"],
      activeTab: "员工风采",
    };
  },
  components: {
    PageHeader,
  },
  watch: {
    $route: {
      immediate: true,
      handler(v) {
        if (v && v.meta.name) {
          this.activeTab = v.meta.name;
        }
      },
    },
  },
  methods: {
    tabChange(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style scoped lang="less">
.invite {
  width: 100%;
  height: 100%;
  padding-top:140px;
  .img-header {
    width: 100%;
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
